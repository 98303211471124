import classnames from 'classnames';
import s from './ContentArea.scss';
import {getProductWidgetSettingsFromProps, WidgetAlignmentEnum, WidgetAreaEnum} from '../../commons/styleParamsService';
import {ActionButton} from '../ActionButton/ActionButton';
import * as React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProvidedTranslationProps} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/translations';
import {ProductDetails} from '../ProductDetails/ProductDetails';

export enum DataHook {
  Root = 'product-widget-content-area',
  Content = 'product-widget-content',
}

export interface IContentAreaProps extends IProvidedGlobalProps, IProvidedTranslationProps {}

@withGlobalProps
@withTranslations('globals.texts')
export class ContentArea extends React.Component<IContentAreaProps> {
  public render() {
    const {
      globals: {isMobile, horizontalLayout},
    } = this.props;
    const settings = getProductWidgetSettingsFromProps(this.props);
    const contentAreaClasses = classnames(s.contentArea, {
      [s.horizontal]: horizontalLayout,
      [s.vertical]: !horizontalLayout,
      [s.borderAreaContent]: settings.hoverBorderArea === WidgetAreaEnum.CONTENT,
    });
    const contentClasses = classnames(s.content, this.getAlignmentClass(settings.alignment), {
      [s.horizontal]: horizontalLayout,
      [s.vertical]: !horizontalLayout,
    });
    const shouldShowActionButton = settings.hoverButtonToggle || settings.widgetButtonToggle;
    return (
      <div data-hook={DataHook.Root} className={contentAreaClasses}>
        <div data-hook={DataHook.Content} className={contentClasses}>
          <ProductDetails hideOnHover={!(isMobile || !settings.hoverButtonToggle)} />
          {shouldShowActionButton ? (
            <ActionButton
              showOnlyOnHover={!isMobile && settings.hoverButtonToggle}
              isCentered={!isMobile && settings.hoverButtonToggle}
              topGutter={isMobile || !settings.hoverButtonToggle}
              onSubmit={this.handleAddToCart}
            />
          ) : null}
        </div>
      </div>
    );
  }

  private getAlignmentClass(alignment: WidgetAlignmentEnum): string {
    switch (alignment) {
      case WidgetAlignmentEnum.LEFT:
        return s.leftText;
      case WidgetAlignmentEnum.RIGHT:
        return s.rightText;
      case WidgetAlignmentEnum.CENTER:
      default:
        return s.centerText;
    }
  }

  private readonly handleAddToCart = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const {globals} = this.props;
    return globals.handleAddToCart();
  };
}
