import * as React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import s from './OutOfStock.scss';

export enum DataHook {
  Root = 'product-widget-OutOfStock',
}

export interface IOutOfStockProps extends IProvidedGlobalProps, IProvidedTranslationProps {}

@withGlobalProps
@withTranslations('globals.texts')
export class OutOfStock extends React.Component<IOutOfStockProps> {
  public render() {
    const {t} = this.props;
    return (
      <div data-hook={DataHook.Root} className={s.outOfStock}>
        {t('OUT_OF_STOCK')}
      </div>
    );
  }
}
