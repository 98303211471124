import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {ProductWidgetApp} from './ProductWidgetApp';
import {bootstrapApp} from '../../commons/bootstrap';
import {PRODUCT_WIDGET_DSN} from '@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf';

export const component = withSentryErrorBoundary(
  bootstrapApp(ProductWidgetApp, {cssPath: ['productWidget.min.css', 'productWidget.stylable.bundle.css']}),
  {
    dsn: PRODUCT_WIDGET_DSN,
    config: {environment: 'Native Component'},
  }
);
