import * as React from 'react';
import s from './ProductName.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import classnames from 'classnames';

export enum DataHook {
  Root = 'product-name',
}

export interface IProductNameProps extends IProvidedGlobalProps {
  name: string;
  bottomGutter?: boolean;
}

@withGlobalProps
export class ProductName extends React.Component<IProductNameProps> {
  public static defaultProps: Partial<IProductNameProps> = {
    bottomGutter: false,
  };

  public render() {
    const {name, bottomGutter, globals} = this.props;
    const classes = classnames(s.productName, {
      [s.bottomGutter]: bottomGutter,
      [s.horizontalTextAlign]: globals.horizontalLayout,
      [s.verticalTextAlign]: !globals.horizontalLayout,
    });
    return (
      <h4 data-hook={DataHook.Root} className={classes}>
        {name}
      </h4>
    );
  }
}
