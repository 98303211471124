import React, {RefObject} from 'react';
import s from './ActionButton.scss';
import classnames from 'classnames';
import {isFunction} from 'util';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {getProductWidgetSettingsFromProps, WidgetActionEnum} from '../../commons/styleParamsService';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/translations';
import {StatesButton} from 'wix-ui-tpa/StatesButton';
import addToCartButtonStylable from './addToCartButton.st.css';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import ProductWidgetLayoutStyle from './../ProductWidgetLayout/ProductWidgetLayout.scss';
import {IProductWidgetSettings} from '../../types/app-types';
export enum DataHook {
  Root = 'action-button',
}

export interface IActionButtonProps extends IProvidedGlobalProps, IProvidedTranslationProps {
  isActionButtonFocused?: boolean;
  onSubmit?: Function;
  showOnlyOnHover?: boolean;
  topGutter?: boolean;
  isCentered?: boolean;
}

@withGlobalProps
@withTranslations('globals.texts')
export class ActionButton extends React.Component<IActionButtonProps> {
  public AddToCartButtonRef: RefObject<StatesButton> = React.createRef();
  private readonly settings: IProductWidgetSettings = getProductWidgetSettingsFromProps(this.props);

  public static defaultProps: Partial<IActionButtonProps> = {
    topGutter: false,
    isCentered: false,
    showOnlyOnHover: false,
  };

  private readonly actionButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();
  private a11yAnnouncer: Announcer;

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('product-widget-announcer');
  }

  public componentDidUpdate(prevProps: IActionButtonProps) {
    if (
      this.props.globals.experiments.newAddToCartButtonProductWidget &&
      this.props.globals.shouldFocusAddToCartButton !== prevProps.globals.shouldFocusAddToCartButton
    ) {
      this.focusActionButton();
    } else if (this.props.globals.shouldFocusOldAddToCartButton !== prevProps.globals.shouldFocusOldAddToCartButton) {
      this.focusActionButton();
    }
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private readonly focusActionButton = () => {
    if (this.props.globals.experiments.newAddToCartButtonProductWidget) {
      this.AddToCartButtonRef.current.focus();
    } else {
      this.actionButtonRef.current && this.actionButtonRef.current.focus();
      if (isFunction(this.props.globals.onFocusTriggered)) {
        this.props.globals.onFocusTriggered();
      }
    }
  };

  private readonly handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    const {onSubmit} = this.props;
    e && e.preventDefault();

    if (isFunction(onSubmit)) {
      e && e.stopPropagation();
      onSubmit(e);
      if (
        this.props.globals.experiments.newAddToCartButtonProductWidget &&
        this.settings.widgetAction === WidgetActionEnum.ADDTOCART
      ) {
        //tslint:disable-next-line:no-floating-promises
        this.AddToCartButtonRef.current.onProgressReset();
        this.a11yAnnouncer.announce(
          this.props.t('sr.ANNOUNCE_ADDED_TO_CART_SINGULAR', {productName: this.props.globals.product.name})
        );
      }
    }
  };

  public render() {
    const {t, topGutter, isCentered, showOnlyOnHover} = this.props;
    const newAddToCartButton = this.props.globals.experiments.newAddToCartButtonProductWidget;

    const text = t(this.getTranslationKeyByAction(this.settings.widgetAction));
    const buttonClasses = classnames({
      [s.actionButton]: !newAddToCartButton,
      [addToCartButtonStylable.root]: newAddToCartButton,
      [s.disabled]: this.isDisabled && !newAddToCartButton,
      [s.topGutter]: topGutter,
      [s.centered]: isCentered,
      [s.actionButtonShowOnlyOnHover]: showOnlyOnHover,
      [ProductWidgetLayoutStyle.actionButtonShowOnlyOnHover]: showOnlyOnHover,
    });

    if (newAddToCartButton) {
      return (
        <StatesButton
          dataHook={DataHook.Root}
          disabled={this.isDisabled}
          ref={this.AddToCartButtonRef}
          onClick={this.handleSubmit}
          text={text}
          type="submit"
          className={classnames(buttonClasses)}
        />
      );
    } else {
      return (
        <button
          className={classnames(buttonClasses)}
          data-hook={DataHook.Root}
          disabled={this.isDisabled}
          ref={this.actionButtonRef}
          type="submit"
          onClick={this.handleSubmit}>
          {text}
        </button>
      );
    }
  }

  private get isDisabled(): boolean {
    const {
      globals: {product},
    } = this.props;
    return product.price === 0;
  }

  private getTranslationKeyByAction(widgetAction: string): string {
    if (widgetAction === WidgetActionEnum.NAVIGATE) {
      return 'NAVIGATE_TO_PRODUCT_PAGE_BUTTON';
    } else if (widgetAction === WidgetActionEnum.ADDTOCART && this.isDisabled) {
      return 'ADD_TO_CART_BUTTON_ZERO_PRICE';
    }
    return 'ADD_TO_CART_BUTTON';
  }
}
